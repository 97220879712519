import React from 'react';
import './index.css';
import BuyNowButton from '../../common/buy-now-button';
import cx from 'classnames';
import {injectIntl, FormattedMessage} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes_l = cx({
        'soft-chew-text': true,
        'soft-chew-content--left': true,
        'soft-chew-text--ru': locale === 'ru'
    });
    const classes_m = cx({
        'soft-chew-text': true,
        'soft-chew-content--middle': true,
        'soft-chew-content--middle--ru': locale === 'ru',
        'soft-chew-text--ru': locale === 'ru'
    });
    const classes_r = cx({
        'soft-chew-text': true,
        'soft-chew-content--right': true,
        'soft-chew-content--right--ru': locale === 'ru',
        'soft-chew-text--ru': locale === 'ru'
    });
    return (
        <div className="soft-chew" id="soft-chew">
            <div className="soft-chew-header">
                <FormattedMessage id="soft.chew.header" />
            </div>
            <div className="soft-chew-content">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/soft-chew-bg--375.png"
                    />
                    <img 
                        className="soft-chew-img"
                        alt=""
                        src="/static/images/soft-chew-bg.png"
                    />
                </picture>
                <div className={classes_l}>
                    <FormattedMessage id="soft.chew.left" />
                </div>
                <div className={classes_m}>
                    <FormattedMessage id="soft.chew.middle" />
                </div>
                <div 
                    className={classes_r}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'soft.chew.right'})}}
                />
                <div className="soft-chew-bottle">
                    <span className="soft-chew__button">
                        <BuyNowButton large />
                    </span>
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/soft-chew-bottle--mobile.png"
                        />
                        <img 
                            className="soft-chew-bottle__img"
                            alt=""
                            src="/static/images/soft-chew-bottle.png"
                        />
                    </picture>
                </div>
            </div>
        </div>
    );
})