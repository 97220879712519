import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'about-vitamin-content__header': true,
        'about-vitamin-content__header--ru': locale === 'ru'
    });
    const classes_img = cx({
        'about-vitamin-bg-mobile': true,
        'about-vitamin-bg-mobile--ru': locale === 'ru'
    });
    return (
        <div className="about-vitamin" id="about-vitamin">
            <div className="about-vitamin-content">
                <div
                    className={classes}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.header'})}}
                />
                <div className="about-vitamin-content__item">
                    <img
                        src="/static/svg/about-vitamin-point.svg"
                    />
                    <div
                        className="about-vitamin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.point.first'})}}
                    />
                </div>
                <div className="about-vitamin-content__item">
                    <img
                        src="/static/svg/about-vitamin-point.svg"
                    />
                    <div
                        className="about-vitamin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.point.second'})}}
                    />
                </div>
                <div className="about-vitamin-content__item">
                    <img
                        src="/static/svg/about-vitamin-point.svg"
                    />
                    <div
                        className="about-vitamin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.point.third'})}}
                    />
                </div>
            </div>
            <div className={classes_img}>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/about-vitamin-background-375.png"
                />
                <img 
                    className="about-vitamin-bg-mobile-img"
                    alt=""
                    src="/static/images/about-vitamin-background-mobile.png"
                />
            </picture>
            </div>
        </div>
    );
})