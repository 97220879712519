import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, text}) => {
        const classes = cx({
                'vitamin-deficiency-item': true
        });
        return (
            <div className={classes}>
                    <img
                        className="vitamin-deficiency-item__img"
                        src={url}
                    />
                    <div className="vitamin-deficiency-item__container">
                        <div className="vitamin-deficiency-item__header">
                                {header}
                        </div>
                        <div className="vitamin-deficiency-item__text">
                                {text}
                        </div>
                    </div>
            </div>
        );
}
