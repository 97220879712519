import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes_l = cx({
        'insufficient-vitamin-text': true,
        'insufficient-vitamin-content--left': true,
        'insufficient-vitamin-content--left--ru': locale === 'ru',
        'insufficient-vitamin-text--ru': locale === 'ru'
    });
    const classes_r = cx({
        'insufficient-vitamin-text': true,
        'insufficient-vitamin-content--right': true,
        'insufficient-vitamin-text--ru': locale === 'ru'
    });
    return (
        <div className="insufficient-vitamin" id="insufficient-vitamin">
            <div 
                className="insufficient-vitamin-header"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'insufficient.vitamin.header'})}}
                />
            <div className="insufficient-vitamin-content">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/insufficient-vitamin-bg--375.png"
                    />
                    <source 
                        media="(max-width: 550px)"
                        srcSet="/static/images/insufficient-vitamin-bg--550.png"
                    />
                    <img 
                        className="insufficient-vitamin-img"
                        alt=""
                        src="/static/images/insufficient-vitamin-bg.png"
                    />
                </picture>
                <div 
                    className={classes_l}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'insufficient.vitamin.left'})}}
                />
                <div 
                    className={classes_r}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'insufficient.vitamin.right'})}}
                />
            </div>
            <div 
                className="insufficient-vitamin-credit"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'insufficient.vitamin.credit'})}}
            />
        </div>
    );
})