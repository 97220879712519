import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'top-banner__text': true,
        'top-banner__text--ru': locale === 'ru'
    });
    const btl_classes = cx({
        'top-banner__image-bottle': true,
        'top-banner__image-bottle--ru': locale === 'ru'
    });
    return (
        <div className="top-banner">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/375.png"
                />
                <source 
                    media="(max-width: 750px)"
                    srcSet="/static/images/top-banner--550.png"
                />
                <source 
                    media="(max-width: 750px)"
                    srcSet="/static/images/top-banner--mobile.png"
                />
                <img 
                    className="top-banner__image"
                    alt=""
                    src="/static/images/top-banner.png"
                />
            </picture>
            <img 
                className="top-banner__image-grass"
                alt=""
                src="/static/images/top-banner-grass.png"
            />
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/top-banner-bottle-mobile.png"
                />
                <source 
                    media="(max-width: 750px)"
                    srcSet="/static/images/top-banner-bottle--550.png"
                />
                <img 
                    className={btl_classes}
                    alt=""
                    src="/static/images/top-banner-bottle.png"
                />
            </picture>
            <div className={classes}>
                <FormattedMessage id="top.banner.text" />
            </div>
        </div>
    );
}
