import React from 'react';
import './HeaderText.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => {
    return (
        <div 
            className="how-much-header-text"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'how.much.header'})}} 
        />
    );
})

