import React from 'react';
import Item from './Item';
import './index.css';
import {injectIntl, FormattedMessage} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-deficiency">
        <div
            className="vitamin-deficiency__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.header'})}}
        />
        <div className="vitamin-deficiency__items">
            <Item
                url="/static/images/vitamin-deficiency-1.png"
                header={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.1.header'})}}/>}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.1.text'})}}/>}
            />
            <Item
                url="/static/images/vitamin-deficiency-2.png"
                header={<FormattedMessage id="vitamin.deficiency.2.header" />}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.2.text'})}}/>}
            />
            <Item
                url="/static/images/vitamin-deficiency-3.png"
                header={<FormattedMessage id="vitamin.deficiency.3.header" />}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.3.text'})}}/>}
            />
        </div>
    </div>
));
