import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/vitamin-d-pro-expert-400-iu-tilgad-10-ml">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="http://www.benu.ee">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/vitamin-d3-pro-expert-400iu-tilgad-koomneoliga-10ml-pmm0146647ee">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/pro-expert-vitamiin-d3-400iu-laste-tilgad-k-mneoli-10ml-n1">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/vitamin-d3-pro-expert-400iu-tilgad-koomneoliga-10ml-pmm0146647ee">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
