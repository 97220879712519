import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, text, reverse}) => {
        const classes = cx({
                'vitamin-pregnancy-item': true,
                'vitamin-pregnancy-item--reverse': reverse
        });
        const classes_img = cx({
                'vitamin-pregnancy-item__img': true,
                'vitamin-pregnancy-item__img--reverse': reverse
        });
        const classes_container = cx({
                'vitamin-pregnancy-item__container': true,
                'vitamin-pregnancy-item__container--reverse': reverse
        });
        return (
            <div className={classes}>
                    <img
                        className={classes_img}
                        src={url}
                    />
                    <div className={classes_container}>
                        <span className="vitamin-pregnancy-item__header">
                                {header}
                        </span>
                        <span className="vitamin-pregnancy-item__text">
                                {text}
                        </span>
                    </div>
            </div>
        );
}
