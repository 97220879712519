import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-dose__text': true,
        'vitamin-dose__image--10': true,
        'vitamin-dose__image--10--ru': locale === 'ru'
    });
    const classes_11 = cx({
        'vitamin-dose__text': true,
        'vitamin-dose__image--11': true,
        'vitamin-dose__image--11--ru': locale === 'ru'
    });
    return (
        <div className="vitamin-dose" id="vitamin-dose">
            <div 
                className="vitamin-dose-header__text"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.dose.header'})}}
            />
            <div className="vitamin-dose__image">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/vitamin-dose-banner--mobile.png"
                    />
                    <img 
                        className="vitamin-dose__image--img"
                        alt=""
                        src="/static/images/vitamin-dose-banner.png"
                    />
                </picture>
                <img 
                    className="vitamin-dose__image--drops"
                    alt=""
                    src="/static/images/vitamin-dose-drops.png"
                />
                <img 
                    className="vitamin-dose__image--bottle"
                    alt=""
                    src="/static/images/vitamin-dose-bottle.png"
                />
                <div 
                    className="vitamin-dose__text vitamin-dose__image--1"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.dose.1'})}}
                />
                <div 
                    className={classes}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.dose.10'})}}
                />
                <div 
                    className={classes_11}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.dose.11'})}}
                />
            </div>
        </div>
    );
})
