import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'how-much-baby__text': true,
        'how-much-baby__text--ru': locale === 'ru'
    });
    return (
        <div className="how-much">
            <HeaderText/>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/how-much-top-375.png"
                />
                <source 
                    media="(max-width: 1000px)"
                    srcSet="/static/images/how-much-top.png"
                />
                <img 
                    className="how-much__image"
                    alt=""
                    src="/static/images/how-much-large.png"
                />
            </picture>
            <div className="how-much-items">
                <Item
                    left
                >
                    <div 
                        className="how-much-item-left-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'how.much.left'})}}                
                    />
                </Item>
                <Item
                    middle
                >
                    <div className="how-much-item-center-text">
                        <FormattedMessage id="how.much.middle" />
                    </div>
                </Item>
                <Item
                    right
                >
                    <div 
                        className="how-much-item-right-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'how.much.right'})}} 
                    />
                </Item>
            </div>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/how-much-bottom-375.png"
                />
                <img 
                    className="how-much__image-bottom"
                    alt=""
                    src="/static/images/how-much-bottom.png"
                />
            </picture>
            <div className="how-much-baby">
                <div className="how-much-baby__wrap">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/how-much-baby-375.png"
                        />
                        <img 
                            className="how-much-baby__image"
                            alt=""
                            src="/static/images/how-much-baby.png"
                        />
                    </picture>
                    <div className={classes}>
                        <FormattedMessage id="how.much.baby" />
                    </div>
                </div>
            </div>
        </div>
    );
})