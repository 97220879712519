import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import VitaminCare from './components/sections/vitamin-care';
import AboutVitamin from './components/sections/about-vitamin';
import HowMuch from './components/sections/how-much';
import MiddleBuyNow from './components/sections/middle-buy-now';
import VitaminDeficiency from './components/sections/vitamin-deficiency';
import VitaminDose from './components/sections/vitamin-dose';
import InsufficientVitamin from './components/sections/insufficient-vitamin';
import SoftChew from './components/sections/soft-chew';
import VitaminPregnancy from './components/sections/vitamin-pregnancy';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <VitaminCare/>
                    <AboutVitamin locale={locale} />
                    <HowMuch locale={locale} />
                    <MiddleBuyNow locale={locale} />
                    <VitaminDeficiency/>
                    <VitaminDose locale={locale} />
                    <InsufficientVitamin locale={locale} />
                    <SoftChew locale={locale} />
                    <VitaminPregnancy/>
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer/>
                </div>
            </IntlProvider>
        );
    }
}

export default Page;
