import React from 'react';
import cx from 'classnames';
import './HeaderTop.css';
import {FormattedMessage, injectIntl} from "react-intl";

const HeaderTop = ({isOpen, toggle, intl, locale}) => {
    const mobileClasses = cx({
        'header-top-mobile__content': true,
        'header-top-mobile__content--open': isOpen
    });
    return (
        <div className="header-top">
            <div className="header-top-desktop">
                <a
                    href="#about-vitamin"
                    className="header-top__link"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({
                        id: "header.top.link.1"
                    })}}
                />
                <a
                    href="#vitamin-dose"
                    className="header-top__link"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({
                            id: "header.top.link.2"
                        })}}
                />
                {/* <a href="#specialists" className="header-top__link">
                 <FormattedMessage id="header.top.link.3" />
                </a> */}
                <a href="#footer" className="header-top__link">
                    <FormattedMessage id="header.top.link.4" />
                </a>
                <a href="#footer-contact" className="header-top__link">
                    <FormattedMessage id="header.top.link.5" />
                </a>
                {
                    locale === 'ru' ? (
                    <a href="/" className="header-top__link">
                        <img src="/static/svg/est.svg" alt="" className="flag"/>
                    </a>
                    ) : (
                    <a href="/ru" className="header-top__link">
                        <img src="/static/svg/rus.svg" alt="" className="flag"/>
                    </a>
                    )
                }
            </div>
            <div className="header-top-mobile">
                <img
                    onClick={toggle}
                    src="/static/svg/hamburger.svg"
                />
                <div className={mobileClasses}>
                    <img
                        className="header-top-mobile__close-btn"
                        onClick={toggle}
                        src="/static/svg/hamburger.svg"
                    />
                    <a
                        onClick={toggle}
                        href="#about-vitamin"
                        className="header-top__link"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({
                                id: "header.top.link.1"
                            })}}
                    />
                    <a
                        onClick={toggle}
                        href="#vitamin-dose"
                        className="header-top__link"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({
                                id: "header.top.link.2"
                            })}}
                    />
                    {/* <a onClick={toggle} href="#specialists" className="header-top__link">
                       <FormattedMessage id="header.top.link.3" />
                    </a> */}
                    <a onClick={toggle} href="#footer" className="header-top__link">
                        <FormattedMessage id="header.top.link.4" />
                    </a>
                    <a onClick={toggle} href="#footer-contact" className="header-top__link">
                        <FormattedMessage id="header.top.link.5" />
                    </a>
                    {
                        locale === 'ru' ? (
                        <a href="/" className="header-top__link">
                            <img src="/static/svg/est.svg" alt="" className="flag"/>
                        </a>
                        ) : (
                        <a href="/ru" className="header-top__link">
                            <img src="/static/svg/rus.svg" alt="" className="flag"/>
                        </a>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default injectIntl(HeaderTop);
