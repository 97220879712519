import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => {
    return (
        <div className="vitamin-care">
            <HeaderText/>
            <div className="vitamin-care-items">
                <Item
                    logo="/static/images/vitamin-care-l.png"
                    header={<FormattedMessage id="vitamin.care.header.l" />}
                >
                    <div 
                        className="vitamin-care-item-left-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.care.text.l'})}}                
                    />
                </Item>
                <Item
                    logo="/static/images/vitamin-care-c.png"
                    header={<FormattedMessage id="vitamin.care.header.c" />}
                >
                    <div 
                        className="vitamin-care-item-center-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.care.text.c'})}} 
                    />
                </Item>
                <Item
                    sm
                    logo="/static/images/vitamin-care-r.png"
                    header={<FormattedMessage id="vitamin.care.header.r" />}
                >
                    <div className="vitamin-care-item-right-text">
                        <FormattedMessage id="vitamin.care.text.r" />
                    </div>
                </Item>
            </div>
        </div>
    );
})