import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({logo, header, children, sm}) => {
    const classes = cx({
        'vitamin-care-item': true,
        'vitamin-care-item--sm': sm
    });
    return(
        <div className={classes}>
            <img
                src={logo}
            />
            <div>
                {header}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}