import React from 'react';
import './Contact.css';
import {FormattedMessage, injectIntl} from "react-intl";

const Item = ({url, children}) => (
    <div className="footer-contact__item">
        <img
            src={url}
            alt=""
        />
        <span>
            {children}
        </span>
    </div>
);

export default injectIntl(({intl}) => {
    const phoneText = intl.formatMessage({id: 'footer.contacts.phone'});
    const emailText = intl.formatMessage({id: 'footer.contacts.email'});
    return (
        <div id="footer-contact" className="footer-contact">
            <Item url="/static/svg/contact-house.svg">
                <FormattedMessage id="footer.contacts.company" />
            </Item>
            <Item url="/static/svg/contact-location.svg">
                <a href="https://www.google.com/maps/place/P%C3%A4rnu+mnt+141,+11314+Tallinn/">
                    <FormattedMessage id="footer.contacts.address" />
                </a>
            </Item>
            <Item url="/static/svg/contact-phone.svg">
                <a href={`tel:${phoneText}`}>
                    {phoneText}
                </a>
            </Item>
            <Item url="/static/svg/contact-email.svg">
                <a href={`mailto:${emailText}`}>
                    {emailText}
                </a>
            </Item>
            <Item url="/static/svg/contact-clock.svg">
                <FormattedMessage id="footer.contacts.hours" />
            </Item>
        </div>
    )
});
