import React from 'react';
import Header from "./Header";
import Locations from "./Locations";
import BuyNowButton from '../../common/buy-now-button';
import Contact from "./Contact";
import './index.css';

export default () => (
    <div id="footer" className="footer">
        <Header/>
        <Locations/>
        <div className="footer__button">
            <BuyNowButton large/>
        </div>
        <hr/>
        <Contact/>
    </div>
);
